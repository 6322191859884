import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";

const RaVerifiedSeal = () => {
    const [sealData, setSealData] = useState(null);
    const companyHashID = 'TzNheEZTazZxNm5ZRk5pdTpkZXJyaWNvc29sdWNvZXNlbXRpZWlhbHRkYQ==';
    const apiUrl = `https://api.reclameaqui.com.br/embed-raverified/hash/${companyHashID}`;

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://s3.amazonaws.com/raichu-beta/ra-verified/styles.css';
        document.head.appendChild(link);

        const fetchSealData = async () => {
            try {
                const response = await fetch(apiUrl);
                if (response.ok) {
                    const data = await response.json();
                    setSealData(data);
                } else {
                    console.error('Erro ao buscar dados:', response.statusText);
                }
            } catch (error) {
                Sentry.captureException(error);
                console.error('Erro ao buscar dados:', error);
            }
        };

        fetchSealData();

        return () => {
            document.head.removeChild(link);
        };
    }, [apiUrl]);

    if (!sealData) {
        return null;
    }

    const widgetUrl = `https://www.reclameaqui.com.br/empresa/${sealData.shortname}/?utm_source=referral&utm_medium=embbed&utm_campaign=ra_verificada&utm_term=horizontal`;

    return (
        <div id="ra-verified-seal">
            <div
                id="ra-widget-verified"
                style={{ width: '136px', height: '48px', overflow: 'hidden', marginTop: '30px', borderRadius: '7px' }}
            >
                <a
                    className="ra-widget-verified-wrapper"
                    target="_blank"
                    title="Selo RA Verificada"
                    href={widgetUrl}
                    rel="noopener noreferrer"
                >
                    <div
                        style={{ visibility: 'visible'}}
                        className="ra-widget-verified-content horizontal ra-verified-loaded"
                    >
                        <img
                            style={{ width: '32px', height: '32px' }}
                            className="ra-widget-verified-seal"
                            src="https://s3.amazonaws.com/raichu-beta/ra-verified/assets/images/verified.svg"
                            alt="Selo RA Verificada"
                            title="Selo RA Verificada"
                        />
                        <span className="ra-widget-verified-text">Verificada por</span>
                        <img
                            style={{ width: '74px', height: '13px' }}
                            className="ra-widget-logo"
                            src="https://s3.amazonaws.com/raichu-beta/ra-verified/assets/images/ra-logo.svg"
                            alt="Selo RA Verificada"
                            title="Selo RA Verificada"
                        />
                    </div>
                </a>
            </div>
        </div>
    );
};

export default RaVerifiedSeal;
